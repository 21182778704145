import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <>
    <div className="header">
    <Navbar expand="lg">
      <Navbar.Brand as={Link} to="/">
        <img className="logo" src="images/shree-nandi.jpeg" style={{ width: '200px', height: 'auto' }} alt="Shree Nandi" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav" className='justify-content-end'>
        <Nav>
        <Nav.Link as={Link} to="/home">
            Home
          </Nav.Link>
          <Nav.Link as={Link} to="/contactus">
            Contact Us
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>
            </>
  );
}

export default Header;