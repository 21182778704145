import React from 'react';
import './App.css';
import ContactUs from './ContactUS/ContactUs';
import {  Route, Routes } from 'react-router-dom';
import Header from './Header/Header';
import Home from './Home/Home';
import MyFooter from './Footer/Footer';

function App() {
  return (
    <div className="App">
          <Header />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contactus" element={<ContactUs />} />
        </Routes>
        <MyFooter />
    </div>
  );
}

export default App;
