import React from "react";
import { Container, Row } from "react-bootstrap";
import './Footer.css';


const MyFooter=()=>{
    return(
        <footer>
            <Container>
                <Row>
                <div class="col-lg-6 col-md-6">
                    For Interior Designs/Job works Contact Us at +91-79753332146
                </div>
                <div class="col-lg-6 col-md-6">
                <div class="copyright text-center">
    Copyright &copy; 2023 <span>Shree Nandi Interiors</span>
  </div>
                </div>
                </Row>
            </Container>
        </footer>
    );
};

export default MyFooter;