import React from 'react';
import MyCarousel from './Carousel/Carousel';
import MyProfile from './MainBlocks/Profile';

const Home=()=>{
    return(
        <>
        <MyCarousel />
        <MyProfile />
        </>
    )
};

export default Home;
