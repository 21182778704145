import React, { useState } from "react";
import './ContactUs.css';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Thank you!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            We appreciate your interest in <b>Shree Nandi Interiors</b>. We got your Query. Will get in touch with you shortly.
            Meanwhile, you can get in touch with us <b>+91-79753332146</b>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button  style={{ backgroundColor: '#000435', color: 'white' }}
                   className='btn btn-navy mx-auto' onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        message: "",
    });

    const [showModal, setShowModal] = useState(false);


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleRegister = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                process.env.REACT_APP_SENDEMAIL_ENDPOINT,
                formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            // Assuming that a successful response has a status of 200
            if (response.status === 200) {
                // Request was successful
                setShowModal(true);
                console.log(response);
                console.log('Data sent successfully');
            } else {
                // Handle errors
                console.log(response);
                console.error('Failed to send data');
            }
        } catch (error) {
            console.error('Error occurred while sending data:', error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({
            name: "",
            phone: "",
            message: "",
        });
    };

    return (
        <>
            <div className="Auth-form-container" id="registerSection">
                <form id="registrationForm" onSubmit={handleRegister} className='Auth-form'>
                    <div className="Auth-form-content">
                        <h4 className="Auth-form-title">We will get back to you</h4>
                        <div className="form-group mt-3">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <input
                                        type="text"
                                        id="name"
                                        className='form-control mt-1'
                                        placeholder="Enter Your Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <input
                                        type="tel"
                                        id="phone"
                                        className='form-control mt-1'
                                        placeholder="Phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-3">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <textarea
                                        id="message"
                                        className='form-control mt-1'
                                        placeholder="Message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-grid gap-2 mt-3">
                            <button
                                type="submit"
                                style={{ backgroundColor: '#000435', color: 'white' }}
                                className='btn btn-navy mx-auto'
                                name="registerButton"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <MyVerticallyCenteredModal
        show={showModal}
        onHide={() => handleCloseModal()}
      />
        </>
    );
};

export default ContactUs;
