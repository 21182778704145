import React from "react";
import { Carousel } from "react-bootstrap";
import Image1 from './CarouselImages/image1.jpg';
import Image2 from './CarouselImages/image2.jpg';
import Image3 from './CarouselImages/image3.jpg';
import Image4 from './CarouselImages/image4.jpg';
import Image5 from './CarouselImages/image5.jpg';
import './Carousel.css';


const MyCarousel=()=>{
    return(<Carousel fade >
<Carousel.Item>
    <img
      className="d-block w-100"
      src={Image1}
      alt="First slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Image2}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Image3}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Image4}
      alt="Fourth slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Image5}
      alt="Fifth slide"
    />
  </Carousel.Item>
    </Carousel>);
};

export default MyCarousel;