import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './Profile.css';

const MyProfile=()=>{
return(<>
    <section>
        <Container>
            <Row className='justify-content-center'>
                <Col lg={12} className="card">
                    <div className="card-wrapper align-left">
                        <h6 className="card-title mbr-fonts-style mb-4 display-1"><strong>About Us</strong></h6>
                        <p className="mbr-text mbr-fonts-style display-7">
                        Welcome to Shree Nandi Interiors, where we transform spaces into captivating works of art. With an unwavering commitment to excellence, we are a premier interior design and job works firm that takes pride in delivering exceptional quality designs and impeccable finishes.

At Shree Nandi Interiors, we believe that every space has a unique story to tell, and our mission is to bring that story to life through innovative and timeless design solutions. Whether it's a residential haven, a corporate office, or a commercial establishment, our team of skilled designers and craftsmen are dedicated to crafting environments that seamlessly blend functionality with aesthetics.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
</>);
};

export default MyProfile;